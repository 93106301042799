.widget {
  &-container {
    position: relative;
    margin-bottom: 32px;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    user-select: none;
    .checkbox-item {
      position: absolute;
      left: 18px;
      bottom: 40px;
      z-index: 1;
    }
    &:hover {
      z-index: 1;
    }
    .chart {
      padding-top: 32px;
      &-legends {
        position: absolute;
        display: flex;
        align-items: center;
        right: 30px;
        bottom: 55px;
        &.double .chart-legend {
          &:nth-child(1):before {
            border-top: 5px solid transparent;
            border-bottom: 0 solid transparent;
            border-left: 0 solid transparent;
            border-right: 11px solid $bluegrey;
          }
          &:nth-child(2):before {
            border-top: 5px solid transparent;
            border-bottom: 0 solid transparent;
            border-left: 0 solid transparent;
            border-right: 11px solid $rusty-red;
          }
        }
      }
      &-legend {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.33;
        letter-spacing: -0.06px;
        margin-left: 25px;
        cursor: pointer;
        &:before {
          content: '';
          display: block;
          width: 11px;
          height: 5px;
          margin-right: 6px;
          margin-bottom: -1px;
          box-sizing: border-box;
        }
        &:nth-child(1):before {
          background: $purpley-pink;
        }
        &:nth-child(2):before {
          background: $cerulean;
        }
      }
    }
    .widget-switcher + .chart {
      padding-top: 0;
    }
    .dropdown-wrapper {
      justify-content: flex-end;
      padding-top: 20px;
      padding-right: 25px;
      & + .chart {
        padding-top: 17px;
      }
      & > label {
        font-size: 12px;
      }
      .Dropdown-control {
        font-size: 14px;
        min-width: 126px;
      }
      .Dropdown-menu {
        right: 0;
      }
    }
    .info {
      margin-top: 15px;
      margin-left: 18px;
    }
  }
  &-title {
    margin-bottom: 8px;
    color: $slate;
    font-size: 21px;
    line-height: 1.43;
    letter-spacing: -0.1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &-footer {
    min-height: 21px;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    border-top: 1px solid $very-light-pink-two;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.43;
    letter-spacing: 0.03px;
    &__title {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: -0.07px;
      color: $brown-grey;
    }
  }
  &-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: $anthrazit;
    line-height: 1.5;
    letter-spacing: -0.08px;
    padding: 16px;
    box-sizing: border-box;
    &.main {
      justify-content: start;
      flex-wrap: wrap;
      background-color: $athens-gray;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      padding-bottom: 32px;
    }
    & > div {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.08px;
      color: $cerulean;
      cursor: pointer;
      transition: 0.3s;
      user-select: none;
      padding: 1px 8px;
      &.active {
        color: $cerulean;
        background-color: rgba(0, 124, 200, 0.1);
      }
      &:hover {
        color: $prussian-blue;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
